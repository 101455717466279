import { isValidURL, getCurrentCountry, isMobileWeb, getCookie, getCurrentLanguage, generateUUID, getEnvironment } from "../utility.service"

const OptimizelyWebExpScript = (options) => {
  const country = getCurrentCountry()

  try {
    if (!!options && !!options.url) {
      const { head, defer, async, beforeLoad } = options
      let url = options.url

      if (isValidURL(url)) {
        return {
          src: url,
          inHead: !!head,
          beforeLoad: !!beforeLoad,
          defer: !!defer,
          async: !!async,
          onload: () => {
            const senderCtrAttr = {
              senderCountry: country.toLowerCase(),
              deviceType: isMobileWeb() ? 'R3_MOBILE_WEB' : 'R3_WEB',
              user_pricing_session: getCookie('userIdentity') ? getCookie('userIdentity') : "newCustomer"
            }
            if (window["optimizely"]) {
              window["optimizely"].push({
                type: "user",
                attributes: senderCtrAttr
              })
            }
          },
        }
      }
      return
    }
    console.log(`Please verify the optimizely web experimentation url: ${options.url}`)
  } catch (e) {
    console.log(e)
    console.log(`There is an error. optimizely web experimentation cannot be loaded.`)
  }
}

const OptimizelyFeatureExpScript = (options) => {
  const country = getCurrentCountry()

  try {
    if (!!options && !!options.url) {
      const { head, defer, async, beforeLoad } = options
      let url = options.url
      return {
        src: url,
        inHead: !!head,
        beforeLoad: !!beforeLoad,
        defer: !!defer,
        async: !!async,
        onload: () => {
          if (window["optimizelySdk"]) {
            const currentEnv = getEnvironment()
            let optimizelyClient = window.optimizelySdk.createInstance({
              sdkKey: currentEnv === 'development' ? options.non_prod_key : options.prod_key
            })
            if (optimizelyClient) {
              optimizelyClient.onReady().then(() => {
                const attributes = {
                  senderCountry: country.toUpperCase(),
                  deviceType: isMobileWeb() ? 'R3_MOBILE_WEB' : 'R3_WEB',
                  user_pricing_session: getCookie('userIdentity') ? getCookie('userIdentity') : "newCustomer",
                  language: getCurrentLanguage()
                }
                let usedId = sessionStorage.getItem('optimizelyUserId')
                if (!usedId) {
                  usedId = generateUUID()
                  sessionStorage.setItem('optimizelyUserId', usedId)
                }
                const user = optimizelyClient.createUserContext(usedId, attributes)
                if (!user) {
                  throw new Error('failed to create user context')
                } else {
                  window['optimizelyFeatureExp'] = user
                }
              })
            }
          }
        },
      }
    }
    console.log(`Please verify the optimizely feature experimentation url: ${options.url}`)
  } catch (e) {
    console.log(e)
    console.log(`There is an error. optimizely feature experimentation cannot be loaded.`)
  }
}

export {
  OptimizelyWebExpScript,
  OptimizelyFeatureExpScript
}